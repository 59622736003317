#cookies-acceptance-modal {
  box-shadow: rgba(0, 0, 0, 0.156863) 0px -2px 5px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 10px 0px;
  max-height: 60%;
  .modal-content {
    padding: 5px;
    p {
      padding: 0;
      margin: 0;
    }
  }
}