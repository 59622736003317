body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}
a {
  color: #d40000;
  font-weight: 500;
  cursor: pointer;
}
p {
  text-align: justify;;
}
ul.left-navigation-index {
  li {
    list-style-type: square;
    margin-top: 6px;
    padding: 10px 10px 10px 0;
    a {
      color: black;
      padding-left: 10px;
      font-weight: 400;
    }
    &:hover {
      color: #d40000;
    }
    &.active a {
      color: #d40000;
      font-weight: 800;
    }
    &:before {
      content: " ";
      border: 1px solid #2a2a2a;
      height: 0;
      width: 150px;
      position: absolute;
      margin-top: -13px;
      margin-left: -17px;
    }
  }
  li:first-child {
    margin-top: 0;
    &:before {
      display: none;
    }
  }
}
.home-heading > * {
  background-color: rgba(0,0,0,0.6);
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  @media #{$medium-and-down} {
    width: 100%;
    word-wrap: break-word;
  }
}