.page-home {

  .home-heading {
    min-height: 300px;
    padding-top: 20px;
    color: white;
    text-shadow: 4px 4px 6px black;
    font-weight: 800;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(/static/img/bombardejos-barcelona-1920.jpg);
  }

  .home-title {
    color: #d40000;
  }
  .home-card {
    display: block;
    h1,h2,h3,h4,h5,h6,p {
      margin-left: 20px;
      margin-right: 20px;
    }
    p {
      padding-bottom: 20px;
      color: black;
    }
  }
}
.page-doc {
  background-color: #f3f3f3;
  .doc-content {
    background-color: white;
    @media #{$large-and-up} {
      padding-left: 50px;
      box-shadow: inset 4px 0 15px 0 rgba(0,0,0,0.5);
      padding-right: 10%;
    }
    @media #{$medium-and-down} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

}